import Actions from "../actions";
import { Action } from "./actions";
import { Navigation } from "../../shared/navigation-models";

export interface State {
  isFetching: boolean;
  data: Navigation;
  error: string;
}

const initialState = {
  isFetching: false,
  data: {
    data: [],
  },
  error: "",
};

const homePage = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case Actions.NAVIGATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: {
          data: [...action.payload.data],
        },
      };
    case Actions.NAVIGATION_REQUEST:
      return { ...state, isFetching: action.payload };
    case Actions.NAVIGATION_FAIL:
      return { ...state, isFetching: false, error: action.payload };
    default:
      return { ...state };
  }
};

export default homePage;
