import React from "react";
import {Typography} from "@mui/material";
import {useLocalStorage} from "./utils/localStorageState";
import {useTranslation} from "react-i18next";

export default function CookieNotice() {
    const [accepted, setAccepted] = useLocalStorage('cookieNotice', '0');
    const {t} = useTranslation();

    if ('1' === accepted) {
        return null;
    }

    return (
        <div style={{backgroundColor: '#0098d1', color: '#fff', position: 'sticky', width: '100%', padding: '30px', bottom: '0px', display: 'block'}}>
            <Typography style={{lineHeight: '35px'}}>
                {t('COOKIE_NOTICE')} <span style={{marginLeft: '40px', backgroundColor: '#96bb55', padding: '10px', cursor: 'pointer'}} onClick={() => {setAccepted('1')}}>{t('COOKIE_NOTICE_APPROVE')}</span>
            </Typography>
        </div>
    );
}
