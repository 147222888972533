import { Action } from "./actions";
import Actions from "../../actions";
import { News } from "../../../shared/news-model";

export interface State {
  isFetching: boolean;
  data?: News;
  error: string;
}

const initialState = {
  isFetching: false,
  data: undefined,
  error: "",
};

const currentUser = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case Actions.CURRENT_NEWS_SUCCESS:
      return {
        ...initialState,
        data: { ...action.payload },
      };
    case Actions.CURRENT_NEWS_REQUEST:
      return { ...initialState, isFetching: action.payload };
    case Actions.CURRENT_NEWS_FAIL:
      return { ...initialState, error: action.payload };
    default:
      return { ...state };
  }
};

export default currentUser;
