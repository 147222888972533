import { Menu } from '../shared/header-models';
import api from '../api/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const fetchMenu = async (query: string): Promise<Menu[]> => {
	const {data} = await api.get<{data: Menu[]}>(`/web/v1/menu?${query}`);

	return data.data;
}

export const useMenu = (inHeader: boolean, inFooter: boolean, langCode: string) => {
	const { t } = useTranslation();
	const [menu, setMenu] = useState<Menu[]>([]);

	useEffect(
		() => {
			const query: string[] = [];

			if (inHeader) {
				query.push('filter[inHeader]=true');
			}
		
			if (inFooter) {
				query.push('filter[inFooter]=true');
			}

			fetchMenu(query.join('&')).then(data => {
				data.unshift({
					id: '',
					type: 'menus',
					attributes: {
						navigationTitle: t('NAV_HOME'),
						slug: '/',
						children: [],
					}
				});

				setMenu(data);
			});
		},
		[langCode],
	);

    return menu;
}