import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import store from "./store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import { QueryClient, QueryClientProvider } from "react-query";
import { AppContextProvider } from "./AppContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
    },
  },
});

const Index = () => {
  return (
    <Suspense fallback={() => <div>Loader...</div>}>
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <AppContextProvider>
              <App />
            </AppContextProvider>
          </Provider>
        </QueryClientProvider>
      </React.StrictMode>
    </Suspense>
  );
};

ReactDOM.render(<Index />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
