import { combineReducers } from "redux";

import singleNews, { State as SingleNewsState } from "./single-news/reducers";
import allNews, { State as NewsState } from "./all-news/reducers";

export interface State {
  currentNews: SingleNewsState;
  allNews: NewsState;
}

export default combineReducers<State>({
  currentNews: singleNews,
  allNews,
});
