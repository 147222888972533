import Actions from "../actions";
import { Action } from "./actions";
import { Page } from "../../shared/page-model";

export interface State {
  isFetching: boolean;
  data?: Page;
  error: string;
}

const initialState = {
  isFetching: false,
  data: undefined,
  error: "",
};

const pageData = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case Actions.PAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: { ...action.payload },
      };
    case Actions.PAGE_REQUEST:
      return { ...state, isFetching: action.payload };
    case Actions.PAGE_FAIL:
      return { ...state, isFetching: false, error: action.payload };
    default:
      return { ...state };
  }
};

export default pageData;
