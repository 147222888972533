import React, { useState, useContext } from "react";
import { CssBaseline, LinkProps } from "@mui/material";
import {
  NavLink as RouterLink,
  NavLinkProps as RouterLinkProps,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const LinkBehavior = React.forwardRef<
  any,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  return (
    <RouterLink
      data-testid="custom-nav-link"
      exact
      ref={ref}
      to={href}
      activeClassName="active-nav"
      {...other}
    />
  );
});

interface ThemeType {
  body: any;
  components: any;
  palette: any;
  typography: {
    fontFamily: string;
    fontSize: number;
    h1: {
      color: string,
      fontSize: string,
      lineHeight: number,
    },
    h2: {
      color: string,
      fontSize: string,
      lineHeight: number,
    }
  };
  spacing: number;
}

interface AppContextInterface {
  appTheme: ThemeType;
  setAppTheme: React.Dispatch<ThemeType>;
}

export const DEFAULT_FONT_SIZE = 14;
export const DEFAULT_FONT_FAMILY = "MazzardH, sans-serif";
export const DEFAULT_BACKGROUND_COLOR = "#ffffff";

export const defaultState = {
  appTheme: {
    body: {
      backgroundColor: "red",
    },
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
      // MuiCssBaseline: {
      //   styleOverrides: `
      //         @font-face {font-family: "Mazzard H"; src: url("https://db.onlinewebfonts.com/t/eb5b5ee332420add9a40ee988cb6ac37.eot"); src: url("https://db.onlinewebfonts.com/t/eb5b5ee332420add9a40ee988cb6ac37.eot?#iefix") format("embedded-opentype"), url("https://db.onlinewebfonts.com/t/eb5b5ee332420add9a40ee988cb6ac37.woff2") format("woff2"), url("https://db.onlinewebfonts.com/t/eb5b5ee332420add9a40ee988cb6ac37.woff") format("woff"), url("https://db.onlinewebfonts.com/t/eb5b5ee332420add9a40ee988cb6ac37.ttf") format("truetype"), url("https://db.onlinewebfonts.com/t/eb5b5ee332420add9a40ee988cb6ac37.svg#Mazzard H") format("svg"); }
      //       `,
      // },
    },
    palette: {
      primary: {
        main: "#1d263a",
      },
      secondary: {
        main: "#96bb55",
      },
      background: {
        default:
          localStorage.getItem("backgroundColor") || DEFAULT_BACKGROUND_COLOR,
        primary:
          localStorage.getItem("backgroundColor") || DEFAULT_BACKGROUND_COLOR,
      },
    },
    typography: {
      fontSize: Number(localStorage.getItem("fontSize")) || DEFAULT_FONT_SIZE,
      fontFamily: localStorage.getItem("fontFamily") || DEFAULT_FONT_FAMILY,
      h1: {
        color: "#96bb55",
        fontSize: '42px',
        lineHeight: 1.19,
      },
      h2: {
        color: "#96bb55",
        fontSize: '42px',
        lineHeight: 1.19,
      }
    },
    spacing: 8,
  },
  setAppTheme: () => null,
};

export const Context = React.createContext<AppContextInterface>(defaultState);

export const AppContextProvider: React.FC = ({ children }) => {
  const [appTheme, setAppTheme] = useState(defaultState.appTheme);

  return (
    <Context.Provider value={{ appTheme, setAppTheme }}>
      <ThemeProvider theme={createTheme(appTheme)}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </Context.Provider>
  );
};

export const useContextConsumer = () => {
  return useContext(Context);
};
