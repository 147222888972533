import React, { useEffect } from "react";
import Routes from "./router";
import Header from "./app/header";
import Footer from "./app/footer";
import CookieNotice from "./cookie-notice";
import {
  BrowserRouter as Router,
  useHistory,
  useLocation,
} from "react-router-dom";
import Accessibility from "./Accessibility";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  // scroll to top while back to previous page
  useEffect(() => {
    if (history.action === "POP") {
      setTimeout(() => {
        window.scrollTo({
          left: 0,
          top: 0,
          behavior: "smooth",
        });
      }, 100);
    }
  }, [history.action]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = React.memo(function App() {
  // const dispatch = useDispatch();
  // const navigation = useSelector((store: RootState) => store.navigation);

  // React.useEffect(() => {
  //   dispatch(getNavigationData());
  // }, [dispatch]);

  // if (navigation.isFetching) {
  //   return null;
  // }

  // const routes = navigation.data.data;

  // const mappedReoutes: MappedRouteType = routes.reduce(
  //   (acc: MappedRouteType, route) => {
  //     const { attributes } = route;
  //     const { position } = attributes;
  //     acc = {
  //       ...acc,
  //       [position]: acc[position]
  //         ? [...acc[position], attributes]
  //         : [attributes],
  //     };
  //     return acc;
  //   },
  //   {}
  // );

  return (
    <div style={{ position: "relative" }}>
      <Router>
        <ScrollToTop />
        <Header />
        <Accessibility />
        <Routes />
        <Footer />
        <CookieNotice/>
      </Router>
    </div>
  );
});

export default App;
