import React from "react";
import { Grid, Link as MuiLink, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import Logo from './../../components/base/Logo';
import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useMenu } from "../../hooks/useHeader";
import FacebookIcon from "../../components/social/FacebookIcon";
import LinkedInIcon from "../../components/social/LinkedInIcon";
import InstagramIcon from "../../components/social/InstagramIcon";
import YoutubeIcon from "../../components/social/YoutubeIcon";
import VimeoIcon from "../../components/social/VimeoIcon";

const Footer: React.FC = () => {
  const { t, i18n } = useTranslation();
  const langCode = i18n.language;
  const theme = useTheme();

  const smBreakpoints = useMediaQuery(theme.breakpoints.up("md"));

  const menuData = useMenu(false, true, langCode);

  return (
    <div className="container">
      <footer style={{ padding: "0 30px" }}>
        <Stack
          direction={smBreakpoints ? "row" : "column"}
          py={smBreakpoints ? 12 : 6}
          paddingBottom={smBreakpoints ? 4 : 2}
          spacing={10}
        >
          <Stack spacing={5} flex={1}>
            <Stack direction="row" spacing={3} alignItems="center">
              <Logo lang={langCode} />
            </Stack>
            <Typography variant="subtitle1" color={grey[600]} maxWidth={300}>
              {t("FOOTER_TEXT")}
            </Typography>
          </Stack>
          <Stack
            direction={smBreakpoints ? "row" : "column"}
            flex={2}
            spacing={smBreakpoints ? 10 : 3}
          >
            {menuData.map((menuItem) =>
              menuItem.attributes.children.length > 0 ? (
                <Stack key={menuItem.attributes.navigationTitle} spacing={smBreakpoints ? 4 : 2} flex={1}>
                  <MuiLink
                       href={
                        menuItem.attributes.slug.startsWith('/')
                          ? menuItem.attributes.slug
                          : `/${menuItem.attributes.slug}`
                      }
                      underline={'none'}
                  >
                    <Typography variant="body2" color="text.secondary">
                      {menuItem.attributes.navigationTitle}
                    </Typography>
                  </MuiLink>
                  <Stack spacing={2}>
                    {menuItem.attributes.children.map((submenuItem) => (
                      <MuiLink
                        key={submenuItem.slug}
                        onClick={() => window.scrollTo(0, 0)}
                        href={
                          submenuItem.slug.startsWith('/')
                            ? submenuItem.slug
                            : `/${submenuItem.slug}`
                        }
                        underline="none"
                        sx={{
                          ":hover": {
                            textDecoration: "underline",
                            fontWeight: 700,
                          },
                        }}
                      >
                        <Typography>
                          {submenuItem.navigationTitle}
                        </Typography>
                      </MuiLink>
                    ))}
                  </Stack>
                </Stack>
              ) : (
                '/' !== menuItem.attributes.slug &&
                (<Stack key={menuItem.attributes.navigationTitle} spacing={4} flex={1}>
                    <MuiLink
                       href={
                        menuItem.attributes.slug.startsWith('/')
                          ? menuItem.attributes.slug
                          : `/${menuItem.attributes.slug}`
                      }
                      underline={'none'}
                    ><Typography variant="body2" color="text.secondary">{menuItem.attributes.navigationTitle}</Typography></MuiLink>
                </Stack>)
              )
            )}
          </Stack>
        </Stack>
          <Grid 
            container 
            spacing={2}
            direction={"column"}
            maxWidth={300}
            marginLeft={smBreakpoints ? "auto" : 0}
            marginRight={smBreakpoints ? 0 : "auto"}
            paddingBottom={2}
          >
              <Grid item style={{paddingLeft: 0}}>
                <Typography>
                  {t("FOLLOW_US")}
                </Typography>
              </Grid>   
              <Grid item style={{paddingLeft: 0}}>
                <Stack direction={"row"} spacing={2}>
                  <FacebookIcon/>
                  <LinkedInIcon/>
                  <InstagramIcon/>
                  <YoutubeIcon/>
                  <VimeoIcon/>
                </Stack>
              </Grid>
          </Grid>
      </footer>
    </div>
  );
};

export default React.memo(Footer);
