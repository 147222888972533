import React from "react";
import { Switch, Route } from "react-router-dom";

const Home = React.lazy(() => import("./pages/home"));
const Foundation = React.lazy(() => import("./pages/foundation/foundation"));
const Knowledge = React.lazy(() => import("./pages/knowledge/knowledge"));
const KnowledgeDetail = React.lazy(
  () => import("./pages/knowledge/knowledge-detail")
);
const PhotoGallery = React.lazy(
  () => import("./pages/knowledge/photo-gallery")
);
const SearchResult = React.lazy(
  () => import("./pages/search-result/search-result")
);
const ExpertServices = React.lazy(
  () => import("./pages/expert-services/expert-services")
);
const Contact = React.lazy(() => import("./pages/contact/contact"));
const Support = React.lazy(() => import("./pages/support"));
const News = React.lazy(() => import("./pages/news"));
const SingleNews = React.lazy(() => import("./pages/news/single-news"));
const Page = React.lazy(() => import("./pages/page/page"));
const Page404 = React.lazy(() => import("./pages/page-404"));

const Routes = React.memo(function Routes() {
  return (
    <Switch>
      <Route path="/vijesti/:slug">
        <SingleNews />
      </Route>
      <Route exact path="/vijesti">
        <News />
      </Route>
      <Route path="/news/:slug">
        <SingleNews />
      </Route>
      <Route exact path="/news">
        <News />
      </Route>
      <Route exact path="/podrska/pregled-podrski">
        <Support />
      </Route>
      <Route path="/knowledge/gallery/:title/:galleryTitle/:galleryId">
        <PhotoGallery />
      </Route>
      <Route path="/knowledgeDetail">
        <KnowledgeDetail />
      </Route>
      <Route exact path="/knowledge">
        <Knowledge />
      </Route>
      <Route exact path="/znanje">
        <Knowledge />
      </Route>
      <Route path="/searchResult">
        <SearchResult />
      </Route>
      <Route path="/zaklada/foundation">
        <Foundation />
      </Route>
      <Route exact path="/pregled-strucne-sluzbe">
        <ExpertServices />
      </Route>
      <Route exact path="/expert-service-overview">
        <ExpertServices />
      </Route>
      <Route exact path="/expert-services">
        <ExpertServices />
      </Route>
      <Route exact path="/staff-overview">
        <ExpertServices />
      </Route>
      <Route exact path="/zaklada/contact">
        <Contact />
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/:slug+">
        <Page />
      </Route>
      <Route path="*">
        <Page404 />
      </Route>
      {/* <Route component={Page} /> */}
    </Switch>
  );
});

export default Routes;
