import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
// import enTranslation from './utils/locales/gb/gb.json';
// import hrTranslation from './utils/locales/hr/hr.json';

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    // resources: {
    //   en: {
    //     translation: enTranslation
    //   },
    //   hr: {
    //     translation: hrTranslation
    //   }
    // },
    fallbackLng: "hr",
    saveMissing: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
