import { Action } from "./actions";
import Actions from "../../actions";
import { News } from "../../../shared/news-model";

export interface State {
  readonly isFetching: boolean;
  readonly data: {news: News[], total: number};
  readonly error: string;
}

const initialState = {
  isFetching: false,
  data: {news: [], total: 0},
  error: "",
};

const currentUser = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case Actions.ALL_NEWS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };
    case Actions.ALL_NEWS_REQUEST:
      return { ...state, isFetching: action.payload };
    case Actions.ALL_NEWS_FAIL:
      return { ...state, isFetching: false, error: action.payload };
    default:
      return { ...state };
  }
};

export default currentUser;
