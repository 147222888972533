import { FC } from 'react';
import { ReactComponent as Icon } from '../../assets/icons/linkedin.svg';
import { SvgIcon } from '@mui/material';

const LinkedInIcon: FC = () => (
    <a href={"https://www.linkedin.com/company/nzrcd/"} target={"_blank"} rel="noreferrer">
        <SvgIcon component={Icon} fontSize="large" viewBox="0 0 512 512"></SvgIcon>
    </a>
);

export default LinkedInIcon;