import React, {useEffect, useState} from "react";
import accessibilityIcon from "../src/assets/images/accessibility.png";
import {
    Box,
    Button,
    Grid,
    IconButton,
    Menu,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import {
    DEFAULT_BACKGROUND_COLOR,
    DEFAULT_FONT_FAMILY,
    DEFAULT_FONT_SIZE,
    useContextConsumer,
} from "./AppContext";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {useTranslation} from "react-i18next";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { defaultState } from './AppContext';

const ItemWrapper = ({children, boxProps, onClick}: any) => (
    <Grid item xs={12} sm={6}>
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                backgroundColor: '#F6F9F0',
                textAlign: "center",
                border: "1px solid #96bb55",
                px: 1,
                height: 90,
                ...boxProps,
            }}
            onClick={onClick}
        >
            {children}
        </Box>
    </Grid>
);

enum Type {
    DECREASE,
    INCREASE,
}

const Accessibility = React.memo(function App() {
    const {t} = useTranslation();
    const {appTheme, setAppTheme} = useContextConsumer();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const smBreakpoint = useMediaQuery(theme.breakpoints.up("sm"));

    const [saturationValue, setSaturationValue] = useState(
        Number(localStorage.getItem("saturationValue")) || 100
    );
    const [contrastValue, setContrastValue] = useState(
        Number(localStorage.getItem("contrastValue")) || 100
    );

    useEffect(() => {
        const filters: string[] = [];

        if (saturationValue === 100) {
            localStorage.removeItem("saturationValue");
        } else {
            localStorage.setItem("saturationValue", saturationValue.toString());
            filters.push(`saturate(${saturationValue}%)`);
        }

        if (contrastValue === 100) {
            localStorage.removeItem("contrastValue");
        } else {
            localStorage.setItem("contrastValue", contrastValue.toString());
            filters.push(`contrast(${contrastValue}%)`);
        }

        if (filters.length > 0) {
            document.body.style.filter = filters.join(' ');
        }
    }, [saturationValue, contrastValue]);

    const handleFontSize = (type: Type) => {
        let fontSize = appTheme.typography.fontSize;

        if (type === Type.INCREASE) {
            if (fontSize + 2 > 20) {
                return;
            }

            fontSize += 2;
        } else {
            if (fontSize - 2 < 10) {
                return;
            }

            fontSize -= 2;
        }
        setAppTheme({
            ...appTheme,
            typography: {
                ...appTheme.typography,
                fontSize,
            },
        });
        localStorage.setItem("fontSize", fontSize.toString());
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleResetSettings = () => {
        document.body.style.filter = "none";
        localStorage.removeItem("saturationValue");
        setSaturationValue(100);

        document.body.style.filter = "none";
        localStorage.removeItem("contrastValue");
        setContrastValue(100);

        setAppTheme({
            ...appTheme,
            palette: {
                ...appTheme.palette,
                background: {
                    default: DEFAULT_BACKGROUND_COLOR,
                },
            },
            typography: {
                ...appTheme.typography,
                fontSize: DEFAULT_FONT_SIZE,
                fontFamily: DEFAULT_FONT_FAMILY,
            },
        });
        localStorage.setItem("fontSize", DEFAULT_FONT_SIZE.toString());
        localStorage.setItem("fontFamily", DEFAULT_FONT_FAMILY);
        localStorage.setItem("backgroundColor", DEFAULT_BACKGROUND_COLOR);
    };

    const handleDyslexiaFont = () => {
        let fontFamily = "OpenDyslexic, sans-serif";

        if (appTheme.typography.fontFamily === fontFamily) {
            fontFamily = DEFAULT_FONT_FAMILY;
        }

        setAppTheme({
            ...appTheme,
            typography: {
                ...appTheme.typography,
                fontFamily,
            },
        });
        localStorage.setItem("fontFamily", fontFamily);
    };

    const handleBackgroundColor = (color: string) => {
        setAppTheme({
            ...appTheme,
            palette: {
                ...appTheme.palette,
                background: {
                    ...appTheme.palette.background,
                    default: color,
                },
            },
        });
        localStorage.setItem("backgroundColor", color);
    };

    const handleSaturation = (type: Type) => {
        if (type === Type.INCREASE) {
            if (saturationValue + 10 > 200) {
                return;
            }

            setSaturationValue(saturationValue + 10);
        } else {
            if (saturationValue - 10 < 0) {
                return;
            }

            setSaturationValue(saturationValue - 10);
        }
    };

    const handleContrast = (type: Type) => {
        if (type === Type.INCREASE) {
            if (contrastValue + 5 > 150) {
                return;
            }

            setContrastValue(contrastValue + 5);
        } else {
            if (contrastValue - 5 < 10) {
                return;
            }

            setContrastValue(contrastValue - 5);
        }
    };

    const accesibilityTheme = defaultState.appTheme;

    accesibilityTheme.typography.fontSize = DEFAULT_FONT_SIZE;

    return (
        <ThemeProvider theme={createTheme(accesibilityTheme)}>
            <div
                style={{
                    position: "sticky",
                    top: 120,
                    marginLeft: 'auto',
                    marginRight: smBreakpoint ? 40 : 0,
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    zIndex: 100,
                    width: smBreakpoint ? 64 : 50,
                    height: smBreakpoint ? 64 : 50,
                }}
            >
                <Button onClick={handleClick}>
                    <img
                        src={accessibilityIcon}
                        style={{
                            width: smBreakpoint ? 50 : 36,
                            height: smBreakpoint ? 50 : 36,
                        }}
                        alt="accessibility"
                    />
                </Button>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <Stack
                        spacing={1}
                        sx={{
                            px: 2,
                            maxHeight: 400,
                            width: smBreakpoint ? 480 : 280,
                        }}
                    >
                        <Stack direction="row" sx={{justifyContent: "flex-end"}}>
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{
                                    textTransform: "none",
                                }}
                                onClick={handleResetSettings}
                            >
                                {t("ACCESSIBILITY.RESET_SETTINGS")}
                            </Button>
                        </Stack>
                        <Grid container spacing={1}>
                            <ItemWrapper>
                                <Typography fontWeight={700}>
                                    {t("ACCESSIBILITY.ADJUST_FONT_SIZE")}
                                </Typography>
                                <Stack direction="row" justifyContent="center" mt={1}>
                                    <IconButton
                                        onClick={() => handleFontSize(Type.DECREASE)}
                                        color="secondary"
                                    >
                                        <RemoveCircleOutlineIcon aria-label="decrease"/>
                                    </IconButton>
                                    <Stack
                                        sx={{
                                            backgroundColor: "#fff",
                                            justifyContent: "center",
                                            px: 2,
                                            borderRadius: 10,
                                        }}
                                    >
                                        <Typography>
                                            {appTheme.typography.fontSize === DEFAULT_FONT_SIZE
                                                ? t("DEFAULT")
                                                : appTheme.typography.fontSize}
                                        </Typography>
                                    </Stack>
                                    <IconButton
                                        onClick={() => handleFontSize(Type.INCREASE)}
                                        color="secondary"
                                    >
                                        <AddCircleOutlineIcon aria-label="increase"/>
                                    </IconButton>
                                </Stack>
                            </ItemWrapper>
                            <ItemWrapper>
                                <Typography fontWeight={700}>
                                    {t("ACCESSIBILITY.ADJUST_SATURATION")}
                                </Typography>
                                <Stack direction="row" justifyContent="center" mt={1}>
                                    <IconButton
                                        onClick={() => handleSaturation(Type.DECREASE)}
                                        color="secondary"
                                    >
                                        <RemoveCircleOutlineIcon aria-label="decrease"/>
                                    </IconButton>
                                    <Stack
                                        sx={{
                                            backgroundColor: "#fff",
                                            justifyContent: "center",
                                            px: 2,
                                            borderRadius: 10,
                                        }}
                                    >
                                        <Typography>
                                            {saturationValue === 100
                                                ? t("DEFAULT")
                                                : `${saturationValue}%`}
                                        </Typography>
                                    </Stack>
                                    <IconButton
                                        onClick={() => handleSaturation(Type.INCREASE)}
                                        color="secondary"
                                    >
                                        <AddCircleOutlineIcon aria-label="increase"/>
                                    </IconButton>
                                </Stack>
                            </ItemWrapper>
                            <ItemWrapper>
                                <Typography fontWeight={700}>
                                    {t("ACCESSIBILITY.ADJUST_CONTRAST")}
                                </Typography>
                                <Stack direction="row" justifyContent="center" mt={1}>
                                    <IconButton
                                        onClick={() => handleContrast(Type.DECREASE)}
                                        color="secondary"
                                    >
                                        <RemoveCircleOutlineIcon aria-label="decrease"/>
                                    </IconButton>
                                    <Stack
                                        sx={{
                                            backgroundColor: "#fff",
                                            justifyContent: "center",
                                            px: 2,
                                            borderRadius: 10,
                                        }}
                                    >
                                        <Typography>
                                            {contrastValue === 100 ? t("DEFAULT") : `${contrastValue}%`}
                                        </Typography>
                                    </Stack>
                                    <IconButton
                                        onClick={() => handleContrast(Type.INCREASE)}
                                        color="secondary"
                                    >
                                        <AddCircleOutlineIcon aria-label="increase"/>
                                    </IconButton>
                                </Stack>
                            </ItemWrapper>
                            <ItemWrapper
                                boxProps={{
                                    alignItems: "center",
                                    cursor: "pointer",
                                    backgroundColor:
                                        appTheme.typography.fontFamily === DEFAULT_FONT_FAMILY
                                            ? "#fff"
                                            : "#F6F9F0",
                                    ":hover": {
                                        border: "1.5px solid #79a037",
                                    },
                                }}
                                onClick={handleDyslexiaFont}
                            >
                                <Typography fontWeight={700}>
                                    {t("ACCESSIBILITY.DYSLEXIA_FONT")}
                                </Typography>
                            </ItemWrapper>
                            <ItemWrapper>
                                <Typography fontWeight={700}>
                                    {t("ACCESSIBILITY.ADJUST_BACKGROUND_COLOR")}
                                </Typography>
                                <Stack direction="row" spacing={1} justifyContent="center" mt={1}>
                                    {[
                                        "#0076B4",
                                        "#7A549C",
                                        "#C83733",
                                        "#D07021",
                                        "#26999F",
                                        "#4D7831",
                                        "#ffffff",
                                        "#000000",
                                    ].map((color) => (
                                        <Box
                                            sx={{
                                                backgroundColor: color,
                                                width: 20,
                                                height: 20,
                                                borderRadius: 1,
                                                cursor: "pointer",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                            onClick={() => handleBackgroundColor(color)}
                                        >
                                            {appTheme.palette.background.default === color && (
                                                <Box
                                                    sx={{
                                                        width: 12,
                                                        height: 12,
                                                        borderRadius: "50%",
                                                        backgroundColor: "#ffffff",
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    ))}
                                </Stack>
                            </ItemWrapper>
                        </Grid>
                    </Stack>
                </Menu>
            </div>
        </ThemeProvider>
    );
});

export default Accessibility;
