import { FC } from 'react';
import { ReactComponent as Icon } from '../../assets/icons/instagram.svg';
import { SvgIcon } from '@mui/material';

const InstagramIcon: FC = () => (
    <a href={"https://www.instagram.com/nzrcd/"} target={"_blank"} rel="noreferrer">
        <SvgIcon component={Icon} fontSize="large" viewBox="0 0 1000 1000"></SvgIcon>
    </a>
);

export default InstagramIcon;