import React, {useEffect, useState} from "react";
import Logo from '../../components/base/Logo';
import {ReactComponent as LogoWithoutText} from "../../assets/icons/logo.svg";
import "./styles.scss";
import {styled} from "@mui/material/styles";
import {
    Menu as MenuIcon,
    Close as CloseIcon,
    ExpandLess,
    ExpandMore,
    Mail,
} from "@mui/icons-material";
import {
    Link as MuiLink,
    Stack,
    Toolbar,
    Box,
    AppBar,
    IconButton,
    Button,
    Container,
    Drawer,
    Collapse,
    useTheme,
    Typography,
    Menu,
    MenuItem,
} from "@mui/material";
import LanguageSelector from "./language-selector";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchBar from "./search-bar";
import {
    useLocalStorage,
} from "../../utils/localStorageState";
import {useMenu} from "../../hooks/useHeader";

const StyledToolbar = styled(Toolbar)(({theme}) => ({
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.main,
}));

type MenuType = {
    nav_title_key: string;
    navigateTo: string;
    subMenuList: SubMenu[];
    open: boolean;
    anchorEl: null | any;
};

type SubMenu = {
    submenu_title_key: string;
    slug?: string;
    navigateTo?: string;
};

const DesktopMenu: React.FC = () => {
    const {t, i18n} = useTranslation();
    const [langCode, setLangCode] = useLocalStorage("language", "hr");
    const history = useHistory();

    const [menuList, setMenuList] = useState<MenuType[]>([]);
    const [activeLink, setActiveLink] = useState(window.location.pathname);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    React.useEffect(() => {
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth);
        });
    }, []);

    const menuData = useMenu(true, false, i18n.language);

    React.useEffect(() => {
        if (menuData) {
            setMenuList(
                menuData.map((menu, i) => ({
                    nav_title_key: menu.attributes.navigationTitle,
                    navigateTo: (false === menu.attributes.slug.startsWith('/') ? '/' : '') + menu.attributes.slug,
                    subMenuList: menu.attributes.children.map((subMenu) => ({
                        submenu_title_key: subMenu.navigationTitle,
                        // slug: subMenu.slug,
                        navigateTo: subMenu.slug.startsWith('/') ? subMenu.slug.slice(1) : subMenu.slug,
                    })),
                    open: false,
                    anchorEl: null,
                }))
            );
        }
    }, [menuData]);

    const handleClick = (
        event: React.MouseEvent<SVGElement> | React.KeyboardEvent<SVGElement>,
        nav_title_key: string
    ) => {
        setMenuList(
            menuList.map((menu, i) => {
                return ({
                    ...menu,
                    anchorEl:
                        menu.nav_title_key === nav_title_key ? event.currentTarget.parentNode : null,
                    open: menu.nav_title_key === nav_title_key,
                })
            })
        );
    };

    const handleClose = () => {
        setMenuList(
            menuList.map((menu) => ({
                ...menu,
                anchorEl: null,
                open: false,
            }))
        );
    };

    return (
        <AppBar
            position="static"
            sx={{
                boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.1)",
                // backgroundColor: theme.palette.common.white,
                background: "none",
            }}
        >
            <Container>
                <StyledToolbar>
                    <div>
                        <Logo 
                            lang={langCode}
                            onClick={() => {
                                setActiveLink('/');
                                history.push("/");
                            }} 
                            height={"58px"}
                        />
                    </div>
                    <Stack
                        direction="row"
                        spacing={4}
                        alignItems="center"
                        sx={{
                            display: windowWidth <= 1024 ? 'none' : 'flex',
                        }}
                    >
                        {menuList.map((menuItem, i) => (
                            <>
                                {menuItem.subMenuList.length > 0 ? (
                                    <Button
                                        sx={{
                                            textTransform: "none",
                                            ":hover": {
                                                background: "none",
                                            },
                                        }}
                                        style={{
                                            marginLeft: (i > 0 && menuList[i - 1].subMenuList.length > 0) ? '16px' : '32px',
                                        }}
                                        endIcon={
                                            menuItem.open
                                                ? <KeyboardArrowUpIcon
                                                    onClick={(e) => {
                                                        handleClick(e, menuItem.nav_title_key);
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (['Enter', ' '].includes(e.key)) {
                                                            handleClick(e, menuItem.nav_title_key);
                                                        }
                                                    }}
                                                    aria-haspopup="true"
                                                    tabIndex={0}
                                                    role="button"
                                                    style={{
                                                        border: "8px solid transparent",
                                                        boxSizing: "content-box",
                                                    }}
                                                    color="secondary"
                                                />
                                                : <KeyboardArrowDownIcon
                                                    onClick={(e) => {
                                                        handleClick(e, menuItem.nav_title_key);
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (['Enter', ' '].includes(e.key)) {
                                                            handleClick(e, menuItem.nav_title_key);
                                                        }
                                                    }}
                                                    aria-haspopup="true"
                                                    tabIndex={0}
                                                    role="button"
                                                    style={{
                                                        border: "8px solid transparent",
                                                        boxSizing: "content-box",
                                                    }}
                                                />
                                        }
                                        tabIndex={-1}
                                    >
                                        <MuiLink
                                            href={menuItem.navigateTo}
                                            underline="none"
                                            sx={{
                                                ":hover": {
                                                    textDecoration: "underline",
                                                },
                                            }}
                                            color={
                                                (
                                                    menuItem.navigateTo === activeLink
                                                    || undefined !== menuItem.subMenuList.find(({
                                                                                                    slug,
                                                                                                    navigateTo
                                                                                                }) => (navigateTo ? `/${navigateTo}` : `/${slug}`) === activeLink)
                                                )
                                                    ? 'secondary'
                                                    : undefined
                                            }
                                            onClick={() => setActiveLink(menuItem.navigateTo)}
                                        >
                                            <Typography>
                                                {t(menuItem.nav_title_key)}
                                            </Typography>
                                        </MuiLink>
                                    </Button>
                                ) : (
                                    <Button
                                        sx={{
                                            textTransform: "none",
                                            ":hover": {
                                                background: "none",
                                            },
                                            height: '48px'
                                        }}
                                        style={{
                                            marginLeft: (i > 0 && menuList[i - 1].subMenuList.length > 0) ? '16px' : '32px',
                                        }}
                                    >
                                        <MuiLink
                                            href={menuItem.navigateTo}
                                            key={menuItem.nav_title_key}
                                            underline="none"
                                            sx={{
                                                ":hover": {
                                                    textDecoration: "underline",
                                                },
                                            }}
                                            color={menuItem.navigateTo === activeLink ? 'secondary' : undefined}
                                            onClick={() => setActiveLink(menuItem.navigateTo)}
                                        >
                                            <Typography>{t(menuItem.nav_title_key)}</Typography>
                                        </MuiLink>
                                    </Button>
                                )}
                                <Menu
                                    anchorEl={menuItem.anchorEl}
                                    open={menuItem.open}
                                    onClose={handleClose}
                                >
                                    {menuItem.subMenuList?.map(
                                        ({submenu_title_key, slug, navigateTo}) => (
                                            <MuiLink
                                                key={submenu_title_key}
                                                href={navigateTo ? `/${navigateTo}` : `/${slug}`}
                                                underline="none"
                                                tabIndex={0}
                                                onKeyDown={(e) => {
                                                    if (['Enter', ' '].includes(e.key)) {
                                                        setActiveLink(navigateTo ? `/${navigateTo}` : `/${slug}`);
                                                    }
                                                }}
                                            >
                                                <MenuItem
                                                    onClick={() => {
                                                        handleClose();
                                                        setActiveLink(navigateTo ? `/${navigateTo}` : `/${slug}`);
                                                    }}
                                                    sx={{
                                                        padding: "10px 30px",
                                                        color: (navigateTo ? `/${navigateTo}` : `/${slug}`) === activeLink ? '#96bb55' : undefined,
                                                    }}
                                                >
                                                    {t(submenu_title_key)}
                                                </MenuItem>
                                            </MuiLink>
                                        )
                                    )}
                                </Menu>
                            </>
                        ))}
                        <SearchBar handleMenuClose={handleClose}/>
                        <LanguageSelector langCode={langCode} setLangCode={setLangCode}/>
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{
                                borderRadius: 16,
                                textTransform: "initial",
                                color: "white",
                                height: 50,
                            }}
                            onClick={() => {
                                history.push('hr' === langCode ? '/zaklada/gdje-se-nalazimo' : '/how-to-reach-uscontact');
                            }}
                        >
                            {
                                (windowWidth > 768 && windowWidth <= 1200)
                                    ? <Mail/>
                                    : t("BUTTON_CONTACT_US")
                            }
                        </Button>
                    </Stack>
                    <MobileMenu
                        menuList={menuList}
                        setMenuList={setMenuList}
                        langCode={langCode}
                        setLangCode={setLangCode}
                    />
                </StyledToolbar>
            </Container>
        </AppBar>
    );
};

export default React.memo(DesktopMenu);

const MobileMenu = React.memo(function MobileMenu({
                                                      menuList,
                                                      setMenuList,
                                                      langCode,
                                                      setLangCode,
                                                  }: {
    menuList: MenuType[];
    setMenuList: React.Dispatch<MenuType[]>;
    langCode: string;
    setLangCode: (code: string) => void;
}) {
    const {t} = useTranslation();

    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
    const [mobileMenuList, setMobileMenuList] = useState<MenuType[]>([]);

    const [activeLink, setActiveLink] = useState(window.location.pathname);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    React.useEffect(() => {
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth);
        });
    }, []);

    const theme = useTheme();

    // const currentMenu =
    //   getStorageValue("language", "hr") === "hr" ? MobileMenuHR : MobileMenuEN;

    useEffect(() => {
        if (menuList) {
            setMobileMenuList(menuList);
        }
    }, [menuList]);

    const handleDrawerToggle = React.useCallback(() => {
        setIsDrawerOpen(!isDrawerOpen);
    }, [isDrawerOpen]);

    const handleSubmenuToggle = React.useCallback(
        (nav_title_key, status) => {
            setMobileMenuList(
                mobileMenuList.map((menu) => ({
                    ...menu,
                    open: menu.nav_title_key === nav_title_key ? !status : menu.open,
                }))
            );
        },
        [mobileMenuList, setMobileMenuList]
    );

    const handleSubmenuClick = () => {
        setIsDrawerOpen(false);
        setMobileMenuList(
            mobileMenuList.map((menu) => ({
                ...menu,
                open: false,
            }))
        );
    };

    const mobileHeader = (showSearchAndLanguage = true) => (
        <Stack direction="row" spacing={2} sx={{alignItems: "center"}}>
            {showSearchAndLanguage &&
                (
                    <>
                        <SearchBar handleMenuClose={handleDrawerToggle}/>
                        <LanguageSelector langCode={langCode} setLangCode={setLangCode}/>
                    </>
                )
            }
            <IconButton
                color="inherit"
                aria-label="toggle drawer"
                onClick={handleDrawerToggle}
                edge="start"
                sx={{mx: 2}}
            >
                {isDrawerOpen ? (
                    <CloseIcon fontSize="large"/>
                ) : (
                    <MenuIcon fontSize="large"/>
                )}
            </IconButton>
        </Stack>
    );

    return (
        <Box
            sx={{
                display: windowWidth > 1024 ? 'none' : 'flex',
            }}
        >
            {mobileHeader(false)}
            <Drawer
                // variant="persistent"
                anchor="top"
                open={isDrawerOpen}
                PaperProps={{
                    sx: {
                        // top: "84px",
                        height: "100%",
                    },
                }}
            >
                <StyledToolbar>
                    <div>
                        <LogoWithoutText cursor="pointer"/>
                    </div>
                    {mobileHeader()}
                </StyledToolbar>
                <Stack py={4}>
                    {mobileMenuList.map((menuItem) => {
                        return (
                            <Box key={menuItem.nav_title_key} mt={3}>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Box
                                        sx={{
                                            cursor: "pointer",
                                            color: menuItem.open
                                                ? theme.palette.secondary.main
                                                : "#333",
                                            ":hover": {
                                                textDecoration: "underline",
                                                fontWeight: 700,
                                            },
                                        }}
                                    >
                                        {menuItem.subMenuList.length > 0
                                            ? (
                                                <MuiLink
                                                    href={menuItem.navigateTo}
                                                    key={menuItem.nav_title_key}
                                                    underline="none"
                                                    sx={{
                                                        ":hover": {
                                                            textDecoration: "underline",
                                                        },
                                                    }}
                                                    color={
                                                        (
                                                            menuItem.navigateTo === activeLink
                                                            || undefined !== menuItem.subMenuList.find(({
                                                                                                            slug,
                                                                                                            navigateTo
                                                                                                        }) => (navigateTo ? `/${navigateTo}` : `/${slug}`) === activeLink)
                                                        )
                                                            ? 'secondary'
                                                            : undefined
                                                    }
                                                    onClick={() => {
                                                        handleSubmenuClick();
                                                        setActiveLink(menuItem.navigateTo);
                                                    }}
                                                >
                                                    <Typography variant="h6">
                                                        {t(menuItem.nav_title_key)}
                                                    </Typography>
                                                </MuiLink>
                                            )
                                            : (
                                                <MuiLink
                                                    href={menuItem.navigateTo}
                                                    key={menuItem.nav_title_key}
                                                    underline="none"
                                                    sx={{
                                                        ":hover": {
                                                            textDecoration: "underline",
                                                        },
                                                    }}
                                                    color={menuItem.navigateTo === activeLink ? 'secondary' : undefined}
                                                    onClick={() => {
                                                        handleSubmenuClick();
                                                        setActiveLink(menuItem.navigateTo);
                                                    }}
                                                >
                                                    <Typography variant="h6">{t(menuItem.nav_title_key)}</Typography>
                                                </MuiLink>
                                            )
                                        }
                                    </Box>
                                    {menuItem.subMenuList.length > 0 ? (
                                        menuItem.open ? (
                                            <ExpandLess
                                                sx={{
                                                    color: menuItem.open
                                                        ? theme.palette.secondary.main
                                                        : "#333",
                                                }}
                                                onClick={() =>
                                                    handleSubmenuToggle(menuItem.nav_title_key, menuItem.open)
                                                }
                                                style={{
                                                    border: "8px solid transparent",
                                                    boxSizing: "content-box",
                                                }}
                                            />
                                        ) : (
                                            <ExpandMore
                                                onClick={() =>
                                                    handleSubmenuToggle(menuItem.nav_title_key, menuItem.open)
                                                }
                                                style={{
                                                    border: "8px solid transparent",
                                                    boxSizing: "content-box",
                                                }}
                                            />
                                        )
                                    ) : null}
                                </Stack>

                                {menuItem.subMenuList.length > 0 ? (
                                    <Collapse
                                        in={menuItem.open}
                                        timeout="auto"
                                        // sx={{ mt: submenu === menuItem.slug ? 2 : 0 }}
                                    >
                                        {menuItem.subMenuList.map(
                                            ({slug, submenu_title_key, navigateTo}) => {
                                                return (
                                                    <MuiLink
                                                        key={slug}
                                                        onClick={() => {
                                                            handleSubmenuClick();
                                                            setActiveLink(navigateTo ? `/${navigateTo}` : `/${slug}`);
                                                        }}
                                                        href={navigateTo ? `/${navigateTo}` : `/${slug}`}
                                                        underline="none"
                                                        sx={{
                                                            color: (navigateTo ? `/${navigateTo}` : `/${slug}`) === activeLink ? '#96bb55' : "#333",
                                                            textAlign: "center",
                                                            cursor: "pointer",
                                                            ":hover": {
                                                                textDecoration: "underline",
                                                                fontWeight: 700,
                                                            },
                                                        }}
                                                    >
                                                        <Typography variant="h6" py={1}>
                                                            {t(submenu_title_key)}
                                                        </Typography>
                                                    </MuiLink>
                                                );
                                            }
                                        )}
                                    </Collapse>
                                ) : null}
                            </Box>
                        );
                    })}
                </Stack>
            </Drawer>
        </Box>
    );
});
