import Actions from "../actions";
import { Action } from "./actions";
import { HomePage } from "../../shared/home-page-models";

export interface State {
  isFetching: boolean;
  data: HomePage;
  error: string;
}

const initialState = {
  isFetching: false,
  data: {
    data: {
      id: "",
      type: "",
    },
    included: [],
  },
  error: "",
};

const homePage = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case Actions.HOME_PAGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: { ...action.payload },
      };
    case Actions.HOME_PAGE_REQUEST:
      return { ...state, isFetching: action.payload };
    case Actions.HOME_PAGE_FAIL:
      return { ...state, isFetching: false, error: action.payload };
    default:
      return { ...state };
  }
};

export default homePage;
