import React, { useState } from "react";
import {
  IconButton,
  Menu,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SearchBar: React.FC<{handleMenuClose: any}> = ({ handleMenuClose }: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState("");

  const breakPoints = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (searchText) {
      handleClose();
      handleMenuClose();
      history.push(`/searchResult?searchTerm=${searchText}`);
    }
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <div className="search-bar">
      <IconButton
        size="large"
        aria-label="search"
        color="inherit"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <SearchIcon />
      </IconButton>
      <Menu
        id="language-selector"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "current-language",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        PaperProps={{
          sx: {
            borderRadius: "10px",
            px: 1,
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <Stack
            direction="row"
            sx={{
              width: breakPoints ? 500 : 350,
              zIndex: 10,
              backgroundColor: "white",
              borderRadius: 3,
            }}
          >
            <input
              style={{
                height: 50,
                width: "100%",
                backgroundColor: "rgba(225, 236, 240, 0.5)",
                outline: "none",
                border: 0,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                padding: "0 22px",
                fontSize: 16,
                color: "#666",
              }}
              placeholder={t("SEARCH_BAR_PLACEHOLDER")}
              onChange={(e) => setSearchText(e.target.value)}
              autoFocus
            />
            <button
              style={{
                backgroundColor: theme.palette.secondary.main,
                color: "white",
                border: "none",
                width: 120,
                cursor: "pointer",
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
              }}
            >
              <SearchIcon />
            </button>
          </Stack>
        </form>
      </Menu>
    </div>
  );
}

export default SearchBar;