import Actions from "../actions";
import { Action } from "./actions";
import { SearchResult } from "../../shared/search-result-models";

export interface State {
  isFetching: boolean;
  data: {
    tender: SearchResult;
    page: SearchResult;
    news: SearchResult;
  }
  error: string;
}

const initialState = {
  isFetching: false,
  data: {
    tender: {
      data: [],
      meta: {
        total: 0
      }
    },
    page: {
      data: [],
      meta: {
        total: 0
      }
    },
    news: {
      data: [],
      meta: {
        total: 0
      }
    }
  },
  error: "",
};

const searchResultPage = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case Actions.SEARCH_RESULT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          [action.payload.type]: action.payload.data
        }
      };
    case Actions.SEARCH_RESULT_REQUEST:
      return { ...state, isFetching: action.payload };
    case Actions.SEARCH_RESULT_FAIL:
      return { ...state, isFetching: false, error: action.payload };
    default:
      return { ...state };
  }
};

export default searchResultPage;
