import Actions from "../actions";
import { Action } from "./actions";
import { TranslationsModel } from "../../shared/translations-model";

export interface State {
  isFetching: boolean;
  data: TranslationsModel;
  error: string;
}

const initialState = {
  isFetching: false,
  data: {
    data: {},
  },
  error: "",
};

const translations = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case Actions.TRANSLATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: { ...action.payload },
      };
    case Actions.TRANSLATIONS_REQUEST:
      return { ...state, isFetching: action.payload };
    case Actions.TRANSLATIONS_FAIL:
      return { ...state, isFetching: false, error: action.payload };
    default:
      return { ...state };
  }
};

export default translations;
