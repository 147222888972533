import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useTheme } from "@mui/material";

const languages: readonly CountryType[] = [
  { code: "en", label: "English", flag: "gb" },
  { code: "hr", label: "Hrvatski", flag: "hr" },
];

export default function LanguageSelector({
  langCode,
  setLangCode,
}: {
  langCode: string;
  setLangCode: (code: string) => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { i18n } = useTranslation();
  const theme = useTheme();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLanguageLabel = (langCode: string): string => {
    // @ts-ignore
    return languages.find<CountryType>((lang) => lang.code === langCode).label;
  };
  const getLanguageFlag = (langCode: string): string => {
    // @ts-ignore
    return languages.find<CountryType>((lang) => lang.code === langCode)?.flag;
  };

  useEffect(() => {
    i18n.changeLanguage(langCode);
    handleClose();
  }, [langCode, i18n]);

  return (
    <div>
      <Button
        id="current-language"
        aria-controls="language-selector"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Box sx={{ mr: theme.spacing(2) }}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${getLanguageFlag(langCode)}.png`}
            srcSet={`https://flagcdn.com/w40/${getLanguageFlag(
              langCode
            )}.png 2x`}
            alt=""
          />
        </Box>{" "}
        {getLanguageLabel(langCode)}
      </Button>
      <Menu
        id="language-selector"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "current-language",
        }}
      >
        {languages.map((lang) => (
          <MenuItem onClick={() => setLangCode(lang.code)} key={lang.code}>
            <Box sx={{ mr: theme.spacing(2) }}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${getLanguageFlag(
                  lang.code
                )}.png`}
                srcSet={`https://flagcdn.com/w40/${getLanguageFlag(
                  lang.code
                )}.png 2x`}
                alt=""
              />
            </Box>{" "}
            {lang.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

interface CountryType {
  code: string;
  label: string;
  flag: string;
}
