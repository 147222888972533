enum Actions {
  HOME_PAGE_REQUEST = "HOME_PAGE_REQUEST",
  HOME_PAGE_FAIL = "HOME_PAGE_FAIL",
  HOME_PAGE_SUCCESS = "HOME_PAGE_SUCCESS",
  TRANSLATIONS_REQUEST = "TRANSLATIONS_REQUEST",
  TRANSLATIONS_FAIL = "TRANSLATIONS_FAIL",
  TRANSLATIONS_SUCCESS = "TRANSLATIONS_SUCCESS",
  CURRENT_NEWS_REQUEST = "CURRENT_NEWS_REQUEST",
  CURRENT_NEWS_FAIL = "CURRENT_NEWS_FAIL",
  CURRENT_NEWS_SUCCESS = "CURRENT_NEWS_SUCCESS",
  ALL_NEWS_REQUEST = "ALL_NEWS_REQUEST",
  ALL_NEWS_FAIL = "ALL_NEWS_FAIL",
  ALL_NEWS_SUCCESS = "ALL_NEWS_SUCCESS",
  NAVIGATION_REQUEST = "NAVIGATION_REQUEST",
  NAVIGATION_FAIL = "NAVIGATION_FAIL",
  NAVIGATION_SUCCESS = "NAVIGATION_SUCCESS",
  PAGE_REQUEST = "PAGE_REQUEST",
  PAGE_FAIL = "PAGE_FAIL",
  PAGE_SUCCESS = "PAGE_SUCCESS",
  SEARCH_RESULT_REQUEST = "SEARCH_RESULT_REQUEST",
  SEARCH_RESULT_FAIL = "SEARCH_RESULT_FAIL",
  SEARCH_RESULT_SUCCESS = "SEARCH_RESULT_SUCCESS",
}

export default Actions;
