import React from 'react';
import SvgIcon from "@mui/material/SvgIcon";

import { ReactComponent as LogoHr } from "../../assets/icons/logo_text_hr.svg";
import { ReactComponent as LogoEn } from "../../assets/icons/logo_text_en.svg";

// h / w
const hrRatio = 118.43 / 163.67;
const enRatio = 62.669 / 86.609;

const hrViewBox = "0 0 163.67 118.43";
const enViewBox = "0 0 86.609 62.669";

const Logo: React.FC<{ 
    lang: string, 
    height?: string, onClick?: () => void 
}> = ({lang, height = "100px", onClick}) => {    
    const width = `${Math.ceil(Number(height.slice(0, -2)) / ('hr' === lang ? hrRatio : enRatio)) + 5}px`;

    return <SvgIcon 
        component={'hr' === lang ? LogoHr : LogoEn} 
        viewBox={'hr' === lang ? hrViewBox : enViewBox}
        style={{
            height,
            width,
        }}
        onClick={onClick}
    />;
}

export default Logo;