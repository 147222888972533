import { FC } from 'react';
import { ReactComponent as Icon } from '../../assets/icons/youtube.svg';
import { SvgIcon } from '@mui/material';

const YoutubeIcon: FC = () => (
    <a href={"https://www.youtube.com/@nzrcd"} target={"_blank"} rel="noreferrer">
        <SvgIcon component={Icon} fontSize="large" viewBox="0 0 32 32"></SvgIcon>
    </a>
);

export default YoutubeIcon;