import axios from "axios";
// import { useHistory } from "react-router-dom";
import { getStorageValue } from "../utils/localStorageState";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(function (config) {
  if (config.headers) {
    const langCode = getStorageValue("language", "hr");
    config.headers["Accept-Language"] = langCode;
  }

  return config;
});

// instance.interceptors.response.use(undefined, (error) => {
//   const history = useHistory();
//   switch (error.response.status) {
//     case 404:
//       history.push("/404");
//       break;
//   }
// });

export default instance;

export interface APIError {
  detail: string;
  code: string;
  title: string;
}
