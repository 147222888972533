// store/index.ts
import { createStore, combineReducers, applyMiddleware } from "redux";
import homePage, { State as HomePageState } from "./home-page/reducers";
import pageData, { State as PageState } from "./page/reducers";
import translations, {
  State as TranslationsState,
} from "./translations/reducers";
import news, { State as NewsState } from "./news/reducers";
import searchResult, { State as SearchResultState } from "./search-result/reducers";
import navigation, { State as NavigationState } from "./navigation/reducers";
import thunk from "redux-thunk";

export interface RootState {
  homePage: HomePageState;
  translations: TranslationsState;
  news: NewsState;
  navigation: NavigationState;
  pageData: PageState
  searchResult: SearchResultState
}

export default createStore(
  combineReducers<RootState>({
    homePage,
    translations,
    news,
    navigation,
    pageData,
    searchResult
  }),
  applyMiddleware(thunk)
);
